import {
  CreativeReportingGroup,
  CreativeReportingGroupResponse,
  CreativeReportingReport,
  CreativeReportingReportResponse,
  CreativeReportingView,
  CreativeReportingViewResponse,
} from "~/types/CreativeReporting";
import { ReportSnapshot, ReportSnapshotResponse } from "~/types/ReportSnapshot";
import { GroupBy, Provider } from "~/types/shared";

export const useCreativeReporting = () => {
  const getMappedCreativeReportingReportSnapshpts = (
    snapshots: Array<ReportSnapshotResponse<CreativeReportingReportResponse>>,
  ): Array<ReportSnapshot<CreativeReportingReport>> => {
    return snapshots.map((snapshot) => {
      return {
        id: snapshot.id,
        uuid: snapshot.uuid,
        type: snapshot.type,
        createdAt: new Date(snapshot.createdAt),
        data: getMappedCreativeReportingReports([snapshot.data])[0],
        creator: snapshot.creator,
        reportId: snapshot.reportId,
      };
    });
  };

  const getMappedCreativeReportingReports = (
    reports: Array<CreativeReportingReportResponse>,
  ): Array<CreativeReportingReport> => {
    const mappedReports = reports.map((report) => {
      return {
        id: report.id,
        uuid: report.uuid,
        title: report.title,
        timeframe: report.timeframe,
        startDate: report.startDate != null ? new Date(report.startDate) : null,
        endDate: report.endDate != null ? new Date(report.endDate) : null,
        createdBy: report.createdBy,
        createdAt: report.createdAt,
        views: getMappedCreativeReportingViews(report.views, report.groupBy),
        clientId: report.clientId,
        description: report.description,
        groupBy: report.groupBy,
        meta: report.meta,
        tiktok: report.tiktok,
        youtube: report.youtube,
        folderId: report.folderId,
        fileSortOrder: report.fileSortOrder,
        workspaceId: report.workspaceId,
        selectedProvider: report.selectedProvider,
        selectedViewType: report.selectedViewType,
      };
    });

    mappedReports.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedReports;
  };

  const getMappedCreativeReportingViews = (
    views: Array<CreativeReportingViewResponse>,
    groupBy: GroupBy,
  ): Array<CreativeReportingView> => {
    return views.map((viewRes) => {
      return {
        info: viewRes.info,
        groups: getMappedCreativeReportingGroups(viewRes.groups, groupBy),
        pageNumber: viewRes.pageNumber,
        total: viewRes.total,
        accumulated: viewRes.accumulated,
      };
    });
  };

  const getMappedCreativeReportingGroups = (
    groups: Array<CreativeReportingGroupResponse>,
    groupBy: GroupBy,
  ): Array<CreativeReportingGroup> => {
    const { getMappedCreatives } = useCreatives();

    return groups.map((groupRes) => {
      const images =
        groupRes.creatives.length > 0
          ? groupRes.creatives.flatMap((c) =>
              Array.isArray(c.info.images) && c.info.images.length > 0
                ? [
                    GroupBy.NONE,
                    GroupBy.ADNAME,
                    GroupBy.CREATIVE_ID,
                    GroupBy.DYNAMIC_CREATIVE,
                    GroupBy.POST_ID,
                    GroupBy.AD_FORMAT,
                  ].includes(groupBy)
                  ? [...c.info.images]
                  : [c.info.images[0]]
                : [],
            )
          : [];

      const videos =
        groupRes.creatives.length > 0
          ? groupRes.creatives.flatMap((c) =>
              Array.isArray(c.info.videos) && c.info.videos.length > 0
                ? [...c.info.videos]
                : [],
            )
          : [];

      const video =
        Array.isArray(videos) && videos.length > 0 ? videos[0] : null;

      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null;

      return {
        info: {
          ...groupRes.info,
          showNoImagesPlaceholder: images.length <= 0,
          images,
          videos,
          image,
          video,
        },
        creatives: getMappedCreatives(groupRes.creatives),
        pageNumber: groupRes.pageNumber,
        total: groupRes.total,
        totalActive: groupRes.totalActive,
        chart: groupRes.chart,
      };
    });
  };

  const getActiveView = (
    provider: Provider,
    report: CreativeReportingReport,
  ): CreativeReportingView | null => {
    if (report.views.length <= 0) {
      return null;
    }
    return (
      report.views.find((view) => view.info.provider === provider) ??
      report.views[0]
    );
  };

  const emptyGroupId = "##empty";

  const getEmptyGroup = (input: {
    idx: number;
    groupBy: GroupBy;
    reportId: number;
    viewId: number;
  }): CreativeReportingGroup => {
    return {
      info: {
        ...input,
        id: emptyGroupId + input.idx,
        title: "",
        showNoImagesPlaceholder: false,
        images: [],
        image: null,
        videos: [],
        video: null,
        selectedCreativeIds: [],
        primaryMetric: {
          name: "",
          curValue: 0,
          compareValue: 0,
          relPosition: 0,
          absPosition: 0,
        },
        metrics: [],
      },
      creatives: [],
      pageNumber: 0,
      total: 0,
      totalActive: 0,
      chart: [],
    };
  };

  const allGroupsNotEmpty = (
    groups: Array<CreativeReportingGroup>,
  ): boolean => {
    return groups.every((group) => !isEmptyGroup(group));
  };

  const isEmptyGroup = (group: CreativeReportingGroup): boolean => {
    return group.info.id.toString().startsWith(emptyGroupId);
  };

  return {
    getMappedCreativeReportingReportSnapshpts,
    getMappedCreativeReportingReports,
    getMappedCreativeReportingViews,
    getMappedCreativeReportingGroups,
    getActiveView,
    getEmptyGroup,
    emptyGroupId,
    allGroupsNotEmpty,
    isEmptyGroup,
  };
};
